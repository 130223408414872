<template>
  <b-col xl="12">

    <b-card>
      <b-card-title class="">
        {{ t('Vendor Edit') }}
      </b-card-title>
      <b-form
        autocomplete="off"
        @submit.prevent="updateVendor"
      >
        <b-row>
          <b-col lg="6">
            <!-- Select Customer_type -->
            <b-col cols="12">
              <b-form-group
                :label="t('Vendor Type')"
                label-for="vi-vendor_type"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Vendor type"
                  vid="vi-vendor_type"
                  rules="required"
                >
                  <v-select
                    v-model="form.vendor_type"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    class="p-0 border-0"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="vendor_type"
                    :placeholder="t('Select Vendor Type')"
                    :reduce="ct => ct.id"
                    :options="vendor_type_list"
                  />
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Select -->
            <b-col cols="12">
              <b-form-group
                :label="t('Name')"
                label-for="vi-Name"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="vi-name"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-name"
                      v-model="form.vendor_name"
                      :class="errors[0] ? 'border-red' : ''"
                      type="text"
                      autocomplete="off"
                      maxlength="100"
                      :placeholder="t('Name')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Select Customer_type -->
            <b-col cols="12">
              <b-form-group
                :label="t('Customer Type')"
                label-for="vi-Customer_type"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer type"
                  vid="vi-Customer_type"
                  rules="required"
                >
                  <v-select
                    v-model="form.customer_type_id"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    class="p-0 border-0"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="customer_type"
                    placeholder=""
                    :reduce="ct => ct.id"
                    :options="customer_type_list"
                  />
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-col>
          <b-col lg="6">
            <!-- Website -->
            <b-col cols="12">
              <b-form-group
                :label="t('Website')"
                label-for="vi-Website"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Website"
                  vid="vi-Website"
                  rules="url"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-Website"
                      v-model="form.website"
                      :class="errors[0] ? 'border-red' : ''"
                      type="text"
                      autocomplete="off"
                      maxlength="100"
                      :placeholder="t('Website')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-row class="px-1">
              <!-- LandLine -->
              <b-col cols="8">
                <b-form-group
                  :label="t('Landline')"
                  label-for="vi-Landline"
                  class="required"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Landline"
                    vid="vi-Landline"
                    rules="required|integer|min:10"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="vi-Landline"
                        v-model="form.landline"
                        type="text"
                        maxlength="15"
                        autocomplete="off"
                        :class="errors[0] ? 'border-red' : ''"
                        :placeholder="t('Landline')"
                        @keypress="onlyNum"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- ext -->
              <b-col cols="4">
                <b-form-group
                  :label="t('Ext.')"
                  label-for="vi-ext"
                  class=""
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Ext."
                    vid="vi-ext"
                    rules="integer|min:3"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="vi-ext"
                        v-model="form.extension"
                        type="text"
                        maxlength="10"
                        autocomplete="off"
                        :class="errors[0] ? 'border-red' : ''"
                        :placeholder="t('Ext.')"
                        @keypress="onlyNum"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-col
          cols="12"
          class="text-center pt-2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            {{ t('Submit') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="$router.push('/vendors/list-vendor')"
          >
            {{ t('Cancel') }}
          </b-button>
        </b-col>
      </b-form>
    </b-card>
  </b-col>

</template>

<script>
// import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import {
  BCard, BCol, BRow, BFormGroup, BInputGroup, BCardTitle, BFormInput, BButton, BForm,
} from 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import { onlyNumber, showLoader, hideLoader } from '@/utils/common'

export default {
  name: 'EditVendor',
  components: {
    vSelect,
    BFormInput,
    BCardTitle,
    BRow,
    ValidationProvider,
    BCol,
    BFormGroup,
    BInputGroup,
    BCard,
    BButton,
    BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: ['req', 'editdata'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      center: { lat: 23.0665966, lng: 72.5321012 },
      required,
      form: {
        vendor_name: '',
        vendor_id: this.$route.params.id,
        vendor_type: '',
        customer_type_id: '',
        landline: '',
        extension: '',
        website: '',
      },
      vendor_type_list: [
        {
          id: 1,
          vendor_type: 'Company',
        },
        {
          id: 2,
          vendor_type: 'Individual',
        },
      ],
      customer_type_list: [],
    }
  },
  async created() {
    showLoader()
    await store.dispatch('ManageLeads/getCustomersTypeList')
      .then(response => {
        if (response.data.code === '200') {
          this.customer_type_list = response.data.data
        }
      })
    await store.dispatch('vendorStore/getVendorById', { vendor_id: this.form.vendor_id }).then(response => {
      this.form.vendor_name = response.data.data.name
      this.form.vendor_type = response.data.data.vendor_type
      this.form.customer_type_id = response.data.data.customer_type_id
      this.form.extension = response.data.data.extension
      this.form.landline = response.data.data.landline
      this.form.website = response.data.data.website
    })
    hideLoader()
  },
  methods: {

    async updateVendor() {
      showLoader()
      await store.dispatch('vendorStore/updateVendor', this.form)
        .then(response => {
          if (response.data.code === '200') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${response.data.msg}`,
                icon: 'Success',
                variant: 'success',
                text: '',
              },
            })
          }
        }).catch(e => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${e.msg}`,
              icon: '',
              variant: 'danger',
              text: '',
            },
          })
        })
      hideLoader()
    },
    onlyNum(a) {
      return onlyNumber(a)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
