var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"xl":"12"}},[_c('b-card',[_c('b-card-title',{},[_vm._v(" "+_vm._s(_vm.t('Vendor Edit'))+" ")]),_c('b-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.updateVendor.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Vendor Type'),"label-for":"vi-vendor_type"}},[_c('validation-provider',{attrs:{"name":"Vendor type","vid":"vi-vendor_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",class:errors[0] ? 'border-red-vselect' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"vendor_type","placeholder":_vm.t('Select Vendor Type'),"reduce":function (ct) { return ct.id; },"options":_vm.vendor_type_list},model:{value:(_vm.form.vendor_type),callback:function ($$v) {_vm.$set(_vm.form, "vendor_type", $$v)},expression:"form.vendor_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Name'),"label-for":"vi-Name"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"vi-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-name","type":"text","autocomplete":"off","maxlength":"100","placeholder":_vm.t('Name')},model:{value:(_vm.form.vendor_name),callback:function ($$v) {_vm.$set(_vm.form, "vendor_name", $$v)},expression:"form.vendor_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Customer Type'),"label-for":"vi-Customer_type"}},[_c('validation-provider',{attrs:{"name":"Customer type","vid":"vi-Customer_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",class:errors[0] ? 'border-red-vselect' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"customer_type","placeholder":"","reduce":function (ct) { return ct.id; },"options":_vm.customer_type_list},model:{value:(_vm.form.customer_type_id),callback:function ($$v) {_vm.$set(_vm.form, "customer_type_id", $$v)},expression:"form.customer_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('Website'),"label-for":"vi-Website"}},[_c('validation-provider',{attrs:{"name":"Website","vid":"vi-Website","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-Website","type":"text","autocomplete":"off","maxlength":"100","placeholder":_vm.t('Website')},model:{value:(_vm.form.website),callback:function ($$v) {_vm.$set(_vm.form, "website", $$v)},expression:"form.website"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-row',{staticClass:"px-1"},[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Landline'),"label-for":"vi-Landline"}},[_c('validation-provider',{attrs:{"name":"Landline","vid":"vi-Landline","rules":"required|integer|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-Landline","type":"text","maxlength":"15","autocomplete":"off","placeholder":_vm.t('Landline')},on:{"keypress":_vm.onlyNum},model:{value:(_vm.form.landline),callback:function ($$v) {_vm.$set(_vm.form, "landline", $$v)},expression:"form.landline"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('Ext.'),"label-for":"vi-ext"}},[_c('validation-provider',{attrs:{"name":"Ext.","vid":"vi-ext","rules":"integer|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:errors[0] ? 'border-red' : '',attrs:{"id":"vi-ext","type":"text","maxlength":"10","autocomplete":"off","placeholder":_vm.t('Ext.')},on:{"keypress":_vm.onlyNum},model:{value:(_vm.form.extension),callback:function ($$v) {_vm.$set(_vm.form, "extension", $$v)},expression:"form.extension"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1)],1)],1)],1),_c('b-col',{staticClass:"text-center pt-2",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.t('Submit'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.push('/vendors/list-vendor')}}},[_vm._v(" "+_vm._s(_vm.t('Cancel'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }